<template>
  <v-app>
    <v-main>
      <v-container class="d-flex justify-center">
        <v-img
          src="../../assets/building.jpg"
          contain
          height="300"
          width="300"
        />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>

export default {
  name: 'AppointmentExamination'
}
</script>
