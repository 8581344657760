import Vue from 'vue'
import { validateMoneyNumber, validateMobile, validateRegexn, validateInteger, validateAccount, validateIP, validateTel, validateFixedTel, validateZipCode, validIdCard, validateyyyy_mm_dd, validateyyyymmdd } from './validate'

Vue.filter('translation', function (rules) {
  const newRules = []
  rules.forEach(rule => {
    if (rule.required) {
        newRules.push(v => !!v || rule.message)
    }
    if (rule.max) {
        newRules.push(v => (v || '').length <= rule.max || ('小于' + rule.max + '个字符'))
    }
    if (rule.type) {
    const type = rule.type
    switch (type) {
      case 'email':
        newRules.push(v => /.+@.+\..+/.test(v) || '请输入正确的邮箱地址')
        break
      case 'money':
        newRules.push(v => validateMoneyNumber(v) || '请输入正确的数字，最多保留两位小数')
        break
      case 'len':
        newRules.push(v => (!!v && v instanceof Array && v.length > 0) || rule.message || '请至少选择一项')
        break
      case 'mobile':
        newRules.push(v => validateMobile(v) || '您输入的手机号不正确')
        break
      case 'tel':
        newRules.push(v => validateTel(v) || '您输入的号码不正确')
        break
      case 'fixed':
        newRules.push(v => validateFixedTel(v) || '您输入的号码不正确')
        break
      case 'regexn':
        newRules.push(v => validateRegexn(v) || '含有非法字符(只能输入字母、汉字)')
        break
      case 'integer':
        newRules.push(v => validateInteger(v) || '请输入正整数')
        break
      case 'account':
        newRules.push(v => validateAccount(v) || '3~20个字符，可使用字母、数字、下划线，需以字母开头')
        break
      case 'ip':
        newRules.push(v => validateIP(v) || '请输入正确的IP地址')
        break
      case 'idCard':
        newRules.push(v => validIdCard(v) || '请输入正确的身份证号码')
        break
      case 'zip':
        newRules.push(v => validateZipCode(v) || '请输入正确的邮政编号')
        break
      case 'yyyy_mm_dd':
        newRules.push(v => validateyyyy_mm_dd(v) || '格式有误')
        break
      case 'yyyymmdd':
        newRules.push(v => validateyyyymmdd(v) || '格式不正确，请输入8位数字')
        break
      default:
        break
    }
  }
  })
  return newRules
})
Vue.filter('cut', function (str) {
  if (str && str.length > 50) {
    str = str.substring(0, 51) + '...'
  }
  return str
})
Vue.filter('sexName', function (sex) {
  switch (sex) {
      case '1':
          return '男'
      case '2':
          return '女'
      default:
          return ''
  }
})
