<template>
  <v-app>
    <v-main>
      <top-title />
      <v-form
        ref="dataForm"
      >
        <v-sheet class="mt-12 pt-12 px-12">
          <v-text-field
            placeholder="身份证号码"
            v-model="wxUser.idCard"
            :rules="rules.idCard | translation"
            autofocus
          />
          <v-btn
            block
            color="primary"
            dark
            class="mt-6"
            @click="unbind"
            v-loading="loading"
          >
            解绑
          </v-btn>
        </v-sheet>
      </v-form>
    </v-main>
    <bottom-nav />
  </v-app>
</template>
<script>
import api from '@/api'
import TopTitle from '@/components/TopTitle'
import { getWxUser } from '@/utils/storageUtils'
export default {
  name: 'UnbindPerson',
  components: {
    TopTitle,
  },
  data () {
    return {
      loading: false,
      wxUser: {
        openId: '',
        idCard: '',
      },
      rules: {
        idCard: [
          { required: true, message: '请输入身份证号码', type: 'idCard' },
          { max: 18 }
        ],
      },
    }
  },
  methods: {
    unbind () {
      this.loading = true
      if (this.$refs.dataForm.validate()) {
        const wxUser = getWxUser()
        const openId = wxUser.openId
        this.wxUser.openId = openId
        this.$http.put(this.$rp(api.wxUser.unbind, openId), this.wxUser)
        .then(() => {
          this.$msg.success('解绑成功')
          this.$router.push({ path: '/My' })
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
  },
}

</script>
