import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 0, // 主页的索引号，0首页，1我的
    curDistrict: {},
  },
  mutations: {
    SET_INDEX (state, index) {
      state.index = index
    },
    SET_CUR_DISTRICT (state, district) {
      state.curDistrict = district
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    GET_INDEX: state => state.index,
    GET_CUR_DISTRICT: state => state.curDistrict,
  }
})
