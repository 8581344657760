<template>
  <v-app>
    <v-main>
      <top-title />
      <div class="text-center">
        <v-btn-toggle
          v-model="tab"
          mandatory
          rounded
          dense
          color="primary"
          class="mt-2 text-center"
        >
          <v-btn
            small
            color="white"
            value="all"
          >
            全部
          </v-btn>
          <v-btn
            small
            color="white"
            value="paying"
          >
            待付款
          </v-btn>
          <v-btn
            small
            color="white"
            value="studying"
          >
            学习中
          </v-btn>
          <v-btn
            small
            color="white"
            value="examing"
          >
            待考试
          </v-btn>
          <v-btn
            small
            color="white"
            value="completed"
          >
            已完成
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-list two-line>
        <template
          v-for="(stu, index) in list"
        >
          <v-list-item
            :key="'stu' + stu.id"
          >
            <v-list-item-content>
              <v-list-item-title v-text="stu.name" />

              <v-list-item-subtitle
                v-text="stu.trainType"
              />

              <v-list-item-subtitle
                v-text="stu.districtName"
              />
              <v-list-item-subtitle>
                {{ stu.addDate }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="tab === 'studying' && stu.period">
                {{ `已学${stu.period}学时` }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-btn
                  rounded
                  color="yellow accent-4"
                  @click="showDetail(stu)"
                  class="ma-1"
                  small
                >
                  查看
                </v-btn>
                <v-btn
                  v-if="stu.payState != 'Paid'"
                  rounded
                  color="yellow accent-4"
                  @click="pay(stu.id)"
                  class="ma-1"
                  small
                >
                  立即支付
                </v-btn>
                <v-btn
                  v-if="stu.payState != 'Paid'"
                  rounded
                  color="red"
                  @click="openConfirm(stu)"
                  class="ma-1"
                  outlined
                  small
                >
                  取消报名
                </v-btn>
                <v-btn
                  v-if="isCompleted(stu)"
                  rounded
                  color="yellow accent-4"
                  @click="downCertificate(stu)"
                  class="ma-1"
                  small
                >
                  下载证明
                </v-btn>
                <v-btn
                  v-if="isStudying(stu)"
                  rounded
                  color="yellow accent-4"
                  @click="startStudy(stu)"
                  class="ma-1"
                  small
                >
                  开始学习
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-list-item-action-text class="green--text">
                {{ getState(stu) }}
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < list.length"
            :key="'divider' + stu.id"
          />
        </template>
      </v-list>

      <Confirm
        :show.sync="confirm"
        no-btn="关闭"
        @yes="logicDel"
        @no="closeConfirm"
      >
        {{ confirmMsg }}
      </Confirm>
    </v-main>
    <bottom-nav />
  </v-app>
</template>

<script>
import api from '@/api'
import { getWxUser } from '@/utils/storageUtils'
import TopTitle from '@/components/TopTitle'
import BottomNav from '@/components/BottomNav'
import { setSessionStore } from '@/utils/webStorage'
export default {
  components: {
    BottomNav,
    TopTitle,
  },
  name: 'Study',
  data: () => ({
    loading: false,
    tab: 'all',
    list: [],
    confirm: false,
    confirmMsg: '',
    selectedStu: undefined,
  }),
  watch: {
    tab () {
      this.getList()
    },
  },
  created () {
    this.getList()
  },
  methods: {
    getList () {
      this.loading = true
      const wxUser = getWxUser()
      const openId = wxUser.openId
      const flag = this.tab
      this.$http.get(api.student.index, {params: { openId, flag }})
      .then(response => {
        this.list = response.data
        this.loading = false
      })
    },
    getState (stu) {
      if (stu.payState !== 'Paid') {
        return '待支付'
      }
      if (stu.trainFlag === 1 && (!stu.examFlag || stu.examFlag === 0)) {
        switch (stu.trainState) {
          case 0:
            return '待学习'
          case 1:
            return '学习中'
          case 2:
            return '学习完成'
          default:
            return ''
        }
      } else if ((!stu.trainFlag || stu.trainFlag === 0) && stu.examFlag === 1) {
        switch (stu.examState) {
          case 0:
            return '待考试'
          case 1:
            return '考试通过'
          default:
            return ''
        }
      } else {
        if (stu.examState === 1) {
          return '考试通过'
        }
        switch (stu.trainState) {
          case 0:
            return '待学习'
          case 1:
            return '学习中'
          case 2:
            return '待考试'
          default:
            return ''
        }
      }
    },
    showDetail (stu) {
      setSessionStore('x-stu-detail', stu)
      this.$router.push({path: '/Detail'})
    },
    pay (stuId) {
      this.$http.get(api.pay.pay, { params: { stuId } }).then(response => {
        const payResponse = response.data
        const that = this
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
              "appId": payResponse.appId, //公众号名称，由商户传入
              "timeStamp": payResponse.timeStamp, //时间戳，自1970年以来的秒数
              "nonceStr": payResponse.nonceStr, //随机串
              "package": payResponse.packageValue,
              "signType": payResponse.signType, //微信签名方式：
              "paySign": payResponse.paySign //微信签名
          },
          res => {
            if (res.err_msg === "get_brand_wcpay_request:ok" ) {
              that.$msg.success('支付成功')
              that.getList()
            } else {
              that.$msg.error('支付失败')
            }
          }
        )
      })
    },
    downCertificate (stu) {
      let fileId = stu.certificateId
      if (fileId) {
        this.goDown(fileId)
      } else {
        this.$http.get(this.$rp(api.student.getCertificateId, stu.id)).then(res => {
          fileId = res.data
          this.goDown(fileId)
        })
      }
    },
    goDown (fileId) {
      this.$http.get(this.$rp(api.file.info, fileId)).then(res => {
        const data = res.data
        const url = data.url
        const fileName = data.fileName
        const query = { url, fileName }
        this.$router.push({ path: '/DownFile', query })
      })
    },
    isCompleted (student) {
      if (student.examFlag === 1) {
        return student.examState === 1
      } else {
        if (student.trainFlag === 1) {
          return student.trainState === 2
        }
        return true
      }
    },
    isStudying (student) {
      if (student.payState !== 'Paid') {
        return false
      }
      if (student.trainFlag !== 1) {
        return false
      }
      return true
    },
    startStudy (stu) {
      this.$http.get(this.$rp(api.student.getStudyWebUrl, stu.id))
      .then(response => {
        const studyWebUrl = response.data
        if (studyWebUrl) {
          window.location = studyWebUrl + '&_=' + Math.random()
        }
      })
    },
    openConfirm (stu) {
      this.selectedStu = stu
      this.confirmMsg = '是否取消此条报名记录？'
      this.confirm = true
    },
    closeConfirm () {
      this.selectedStu = undefined
      this.confirm = false
      this.confirmMsg = ''
    },
    logicDel () {
      const { id } = this.selectedStu
      this.$http.delete(this.$rp(api.student.logicDel, id))
      .then(() => {
        this.closeConfirm()
        this.$msg.success('已取消')
        this.getList()
      })
    },
  }
};
</script>
