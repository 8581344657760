import { setSessionStore, getSessionStore, removeSessionStore } from './webStorage'

const CONST_WX_USER = 'WX_USER'
const CONST_MY_INFO = 'MY_INFO'
const CONST_QUERY_STRING = 'QUERY_STRING'

export function setWxUser(wxUser) {
  if (wxUser) {
    setSessionStore(CONST_WX_USER, wxUser)
  } else {
    removeSessionStore(CONST_WX_USER)
  }
}

export function delWxUser() {
  removeSessionStore(CONST_WX_USER)
}

export function getWxUser() {
  return getSessionStore(CONST_WX_USER)
}

export function setMyInfo(myinfo) {
  if (myinfo) {
    setSessionStore(CONST_MY_INFO, myinfo)
  } else {
    removeSessionStore(CONST_MY_INFO)
  }
}

export function getMyInfo() {
  return getSessionStore(CONST_MY_INFO)
}

export function setQueryStr(queryStr) {
  if (queryStr) {
    setSessionStore(CONST_QUERY_STRING, queryStr)
  } else {
    removeSessionStore(CONST_QUERY_STRING)
  }
}

export function getQueryStr() {
  return getSessionStore(CONST_QUERY_STRING)
}
