<template>
  <v-app>
    <v-main>
      <top-title />
      <v-tabs
        v-model="tab"
        centered
      >
        <v-tab>
          报名信息
        </v-tab>
        <v-tab>
          上传材料
        </v-tab>
      </v-tabs>
      
      <v-tabs-items
        v-model="tab"
        class="px-4"
      >
        <v-tab-item
          key="报名信息"
        >
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              姓 名
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.name }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              身份证号
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.idCard }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              性 别
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.sex | sexName }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              出生日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.birthDate }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              住 址
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.address }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              手 机 号
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.phone }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              准驾车型
            </v-col>
            <v-col cols="7">
              {{ stu.driverClass }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              驾驶证初领日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.driverFirstDate }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.driverArchiveNo"
          >
            <v-col
              cols="5"
            >
              驾驶证档案编号
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.driverArchiveNo }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.driverArchiveNo" />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              报名日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.addDate }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              从业资格类别
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.trainType }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.certificateNo"
          >
            <v-col
              cols="5"
            >
              从业资格证号
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.certificateNo }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.certificateNo" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.certificateFirstDate"
          >
            <v-col
              cols="5"
            >
              初次领证日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.certificateFirstDate }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.certificateFirstDate" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.orgName"
          >
            <v-col
              cols="5"
            >
              发证机关
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.orgName }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.orgName" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.expiryDate"
          >
            <v-col
              cols="5"
            >
              资格有效日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.expiryDate }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.expiryDate" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.stageStartDate"
          >
            <v-col
              cols="5"
            >
              阶 段
            </v-col>
            <v-col
              cols="7"
            >
              {{ `${stu.stageStartDate} 至 ${stu.stageEndDate}` }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.stageStartDate" />
          <v-row
            align="baseline"
            class="ma-0"
          >
            <v-col
              cols="5"
            >
              地 市
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.districtName }}
            </v-col>
          </v-row>
          <v-divider />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.trainFlag && stu.trainMode"
          >
            <v-col
              cols="5"
            >
              培训方式
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.trainMode | trainModeName }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.trainFlag && stu.trainMode" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.trainFlag && stu.courseName"
          >
            <v-col
              cols="5"
            >
              课 程
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.courseName }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.trainFlag && stu.courseName" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.trainFlag && stu.period"
          >
            <v-col
              cols="5"
            >
              完成学时
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.period }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.trainFlag && stu.period" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.trainFlag && stu.trainFinishDate"
          >
            <v-col
              cols="5"
            >
              培训完成日期
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.trainFinishDate }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.trainFlag && stu.trainFinishDate" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.examFlag && stu.examMode"
          >
            <v-col
              cols="5"
            >
              考试方式
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.examMode | examModeName }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.examFlag && stu.examMode" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.examFlag && stu.examFinishTime"
          >
            <v-col
              cols="5"
            >
              考试通过时间
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.examFinishTime }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.examFlag && stu.examFinishTime" />
          <v-row
            align="baseline"
            class="ma-0"
            v-if="stu.examFlag && stu.examScore"
          >
            <v-col
              cols="5"
            >
              成绩
            </v-col>
            <v-col
              cols="7"
            >
              {{ stu.examScore | examScore(' ') }}
            </v-col>
          </v-row>
          <v-divider v-if="stu.examFlag && stu.examScore" />
          <v-chip
            class="mt-2 mr-2"
            :color="activeColor"
            text-color="white"
          >
            {{ activeState }}
          </v-chip>
          <v-chip
            class="mt-2 mr-2"
            :color="trainPlanColor"
            text-color="white"
            v-if="stu.trainFlag === 1 && stu.trainMode === 'Classroom'"
          >
            {{ trainPlanState }}
          </v-chip>
          <v-chip
            class="mt-2 mr-2"
            :color="trainStateColor"
            text-color="white"
            v-if="stu.trainFlag === 1"
          >
            {{ trainState }}
          </v-chip>
          <v-chip
            class="mt-2 mr-2"
            :color="examPlanColor"
            text-color="white"
            v-if="stu.examFlag === 1 && stu.examMode === 'Classroom'"
          >
            {{ examPlanState }}
          </v-chip>
          <v-chip
            class="mt-2 mr-2"
            :color="examStateColor"
            text-color="white"
            v-if="stu.examFlag === 1"
          >
            {{ examState }}
          </v-chip>
        </v-tab-item>
        <v-tab-item
          key="上传材料"
        >
          <v-row v-if="files && files.length > 0">
            <v-col
              v-for="file in files"
              :key="file.id"
              sm="3"
              md="2"
              cols="4"
              class="text-center"
            >
              <el-image
                :src="file.url"
                :preview-src-list="[file.url]"
                style="height: 100px;"
                fit="cover"
              />
              <div>
                {{ file.fileName }}
              </div>
            </v-col>
          </v-row>
          <div
            class="d-flex justify-center pt-12"
            v-else
          >
            未上传材料
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-main>
  </v-app>
</template>
<script>
import { getSessionStore } from '@/utils/webStorage'
import api from '@/api'
import TopTitle from '@/components/TopTitle'
export default {
  name: 'Detail',
  components: {
    TopTitle,
  },
  filters: {
    trainModeName (mode) {
      switch (mode) {
        case 'Classroom':
          return '课堂培训'
        case 'Online':
          return '网络远程培训'
        default:
          return ''
      }
    },
    examModeName (mode) {
      switch (mode) {
        case 'Classroom':
          return '上机考试'
        case 'Online':
          return '在线测验'
        default:
          return ''
      }
    },
    examScore (value, sep) {
      if (value) {
        try {
          const examScoreJson = JSON.parse(value)
          const scoreAry = []
          examScoreJson.forEach(item => {
            scoreAry.push(item.subject + '：' + item.score)
          })
          return scoreAry.join(sep)
        } catch (e) {
          return value
        }
      } else {
        return ''
      }
    }
  },
  created () {
    this.getStu()
  },
  data () {
    return {
      stu: {
        openId: '',
        districtId: undefined,
        name: '',
        idCard: '',
        idType: 1,
        sex: '2',
        phone: '',
        address: '',
        driverClass: '',
        driverFirstDate: '',
        trainTypeCode: '',
        eduTypeCode: 0,
        trainFlag: undefined,
        trainMode: undefined,
      },
      tab: '',
      files: []
    }
  },
  computed: {
    activeColor () {
      return this.stu.payState === 'Paid' ? 'green' : 'orange'
    },
    activeState () {
      return this.stu.payState === 'Paid' ? '已支付' : '待支付'
    },
    trainPlanColor () {
      return this.stu.trainPlanId ? 'green' : 'orange'
    },
    trainPlanState () {
      return this.stu.trainPlanId ? '已预约培训' : '培训未预约'
    },
    trainStateColor () {
      return this.stu.trainState === 2 ? 'green' : 'orange'
    },
    trainState () {
      switch (this.stu.trainState) {
        case 0:
          return '待学习'
        case 1:
          return '学习中'
        case 2:
          return '学习完成'
        default:
          return ''
      }
    },
    examPlanColor () {
      return this.stu.examPlanId ? 'green' : 'orange'
    },
    examPlanState () {
      return this.stu.examPlanId ? '已预约考试' : '考试未预约'
    },
    examStateColor () {
      return this.stu.examState === 1 ? 'green' : 'orange'
    },
    examState () {
      switch (this.stu.examState) {
        case undefined:
          return '待考试'
        case 0:
          return '待考试'
        case 1:
          return '考试通过'
        default:
          return ''
      }
    },
  },
  methods: {
    getStu () {
      this.stu = getSessionStore('x-stu-detail')
      if (this.stu.files) {
        const files = JSON.parse(this.stu.files)
        const promiseAry = []
        if (files && files.length > 0) {
          files.forEach(file => {
            promiseAry.push(this.getFileUrl(file))
          })
          Promise.all(promiseAry).then(res => {
            this.files = [...res]
          })
        }
      }
    },
    getFileUrl (file) {
      return new Promise(resolve => {
        this.$http.get(this.$rp(api.file.info, file.fileId))
        .then(res => {
          resolve(Object.assign({}, res.data, file))
        })
      })
    },
  },
}

</script>
