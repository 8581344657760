<template>
  <v-app>
    <v-main>
      <my-top-title />
    </v-main>
    <bottom-nav />
  </v-app>
</template>

<script>
import MyTopTitle from '@/components/MyTopTitle'
import BottomNav from '@/components/BottomNav'
export default {
  components: {
    BottomNav,
    MyTopTitle,
  },
  name: 'My',
};
</script>
