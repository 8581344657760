import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import My from '@/views/My.vue'
import EmpReg from '@/views/reg/EmpReg'
import EmpResumeReg from '@/views/reg/EmpResumeReg'
import ConeduReg from '@/views/reg/ConeduReg'
import Conedu12Reg from '@/views/reg/Conedu12Reg'
import AppointmentTrain from '@/views/appointment/AppointmentTrain'
import AppointmentExamination from '@/views/appointment/AppointmentExamination'
import Authentication from '@/views/my/Authentication'
import Detail from '@/views/my/Detail'
import DownFile from '@/views/my/DownFile'
import BindPerson from '@/views/my/BindPerson'
import UnbindPerson from '@/views/my/UnbindPerson'
import Study from '@/views/Study.vue'
import ClearscoreReg from '@/views/reg/ClearscoreReg'
import Taxipatch from '@/views/reg/Taxipatch'
import ConeduReg42 from '@/views/reg/ConeduReg42'
import ConeduReg66 from '@/views/reg/ConeduReg66'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      title: '首 页'
    }
  },
  {
    path: '/My',
    name: 'My',
    component: My,
    meta: {
      title: '我 的'
    }
  },
  {
    path: '/EmpReg',
    name: 'EmpReg',
    component: EmpReg,
    meta: {
      title: '从业资格报名'
    }
  },
  {
    path: '/EmpResumeReg',
    name: 'EmpResumeReg',
    component: EmpResumeReg,
    meta: {
      title: '过期重考'
    }
  },
  {
    path: '/ConeduReg',
    name: 'ConeduReg',
    component: ConeduReg,
    meta: {
      title: '继续教育报名'
    }
  },
  {
    path: '/Conedu12Reg',
    name: 'Conedu12Reg',
    component: Conedu12Reg,
    meta: {
      title: '12学时继续教育报名'
    }
  },
  {
    path: '/conedu-reg-42',
    name: 'ConeduReg42',
    component: ConeduReg42,
    meta: {
      title: '湖北继续教育报名'
    }
  },
  {
    path: '/conedu-reg-66',
    name: 'ConeduReg66',
    component: ConeduReg66,
    meta: {
      title: '兵团继续教育报名'
    }
  },
  {
    path: '/taxi-patch',
    component: Taxipatch,
    meta: {
      title: '出租车补学'
    }
  },
  {
    path: '/AppointmentTrain',
    name: 'AppointmentTrain',
    component: AppointmentTrain,
    meta: {
      title: '预约培训'
    }
  },
  {
    path: '/AppointmentExamination',
    name: 'AppointmentExamination',
    component: AppointmentExamination,
    meta: {
      title: '预约考试'
    }
  },
  {
    path: '/Authentication',
    name: 'Authentication',
    component: Authentication,
    meta: {
      title: '身份认证'
    }
  },
  {
    path: '/Detail',
    name: 'Detail',
    component: Detail,
    meta: {
      title: '我的报名'
    }
  },
  {
    path: '/DownFile',
    name: 'DownFile',
    component: DownFile,
    meta: {
      title: '下载证明材料'
    }
  },
  {
    path: '/bindPerson',
    name: 'BindPerson',
    component: BindPerson,
    meta: {
      title: '绑定信息'
    }
  },
  {
    path: '/unbind',
    component: UnbindPerson,
    meta: {
      title: '解绑'
    }
  },
  {
    path: '/Study',
    name: 'Study',
    component: Study,
    meta: {
      title: '学 习'
    },
  },
  {
    path: '/clearscoreReg',
    name: 'ClearscoreReg',
    component: ClearscoreReg,
    meta: {
      title: '清分学习'
    }
  },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
