import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from '@/router'
import store from './store'
import utils from '@/utils'
import http from '@/utils/http'
import '@/utils/filters'
import Alert from '@/components/layer/Alert'
import Confirm from '@/components/layer/Confirm'

Vue.use(utils)
Vue.use(http)
Vue.component('Alert', Alert)
Vue.component('Confirm', Confirm)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
