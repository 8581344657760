<template>
  <v-app>
    <v-main
      class="grey lighten-4"
      v-loading="loading"
    >
      <v-container>
        <v-stepper
          v-model="step"
          alt-labels
          elevation="0"
          class="grey lighten-4"
        >
          <v-stepper-header class="white elevation-0 pb-2">
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              上传信息
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              从业资格信息
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 3"
              step="3"
            >
              上传材料
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 4"
              step="4"
            >
              注册完成
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="pa-0"
            >
              <v-sheet class="mt-2">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>身份证</v-list-item-title>
                      <v-list-item-subtitle>点击拍摄身份证照片（人像面）</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-upload
                        :action="idcardocrPath"
                        :data="{ openId: wxUser.openId, appId: appId }"
                        :show-file-list="false"
                        accept="image/*"
                        :before-upload="idCardBefore"
                        :on-success="idCardUpload"
                      >
                        <v-btn
                          color="indigo"
                          dark
                          rounded
                          v-loading="idcardLoading"
                        >
                          {{ idcardocr ? '已拍照' : '拍照' }}
                        </v-btn>
                      </el-upload>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>本人面部照片</v-list-item-title>
                      <v-list-item-subtitle>点击拍摄面部照片</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-upload
                        :action="facePath"
                        :data="{ openId: wxUser.openId, appId: appId }"
                        :show-file-list="false"
                        accept="image/*"
                        :before-upload="faceBefore"
                        :on-success="faceUpload"
                      >
                        <v-btn
                          color="indigo"
                          dark
                          rounded
                          v-loading="faceLoading"
                        >
                          {{ face ? '已拍照' : '拍照' }}
                        </v-btn>
                      </el-upload>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
              <v-sheet class="mt-2 px-6">
                <v-form
                  ref="dataForm"
                >
                  <v-row
                    no-gutters
                    align="baseline"
                    class="py-3"
                  >
                    <v-col
                      cols="5"
                    >
                      姓名
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      {{ wxUser.name }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                    class="py-3"
                  >
                    <v-col
                      cols="5"
                    >
                      身份证号
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      {{ wxUser.idCard }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                    class="py-3"
                  >
                    <v-col
                      cols="5"
                    >
                      性别
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      {{ wxUser.sex | sexName }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                    class="py-3"
                  >
                    <v-col
                      cols="5"
                    >
                      出生日期
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      {{ wxUser.birthDate }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                    class="py-3"
                  >
                    <v-col
                      cols="5"
                    >
                      住址
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      {{ wxUser.address }}
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      手机号
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-text-field
                        type="number"
                        placeholder="请输入手机号"
                        v-model="wxUser.phone"
                        :rules="rules.phone | translation"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      准驾车型
                    </v-col>
                    <v-col cols="7">
                      <v-bottom-sheet scrollable>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择准驾车型"
                            v-model="wxUser.driverClass"
                            hide-details
                            v-on="on"
                            class="no-border"
                          />
                        </template>
                        <v-card>
                          <v-card-title>请选择准驾车型（可多选）</v-card-title>
                          <v-card-text style="height: 200px;">
                            <v-checkbox
                              v-model="driverClass"
                              v-for="key in driverClassList"
                              :key="key"
                              :label="key"
                              :value="key"
                              hide-details
                              multiple
                            />
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      驾驶证初领日期
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-bottom-sheet>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择驾驶证初次领证日期"
                            v-model="wxUser.driverFirstDate"
                            hide-details
                            v-on="on"
                            class="no-border"
                          />
                        </template>
                        <v-card>
                          <v-card-title>
                            请选择驾驶证初次领证日期
                          </v-card-title>
                          <v-card-text class="pa-0">
                            <v-date-picker
                              v-model="wxUser.driverFirstDate"
                              full-width
                              no-title
                              scrollable
                            />
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      驾驶证档案编号
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-text-field
                        type="number"
                        placeholder="请输入驾驶证档案编号"
                        v-model="wxUser.driverArchiveNo"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      城市
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-bottom-sheet>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择城市"
                            :value="district.districtName"
                            :rules="rules.districtId | translation"
                            hide-details
                            class="no-border"
                            validate-on-blur
                            v-on="on"
                            :disabled="districtDisabled"
                          />
                        </template>
                        <v-card>
                          <v-card-title>请选择城市</v-card-title>
                          <v-card-text>
                            <v-radio-group
                              dense
                              v-model="district"
                              hide-details
                              class="my-0 pa-2"
                            >
                              <v-radio
                                v-for="district in openDistricts"
                                :key="district.districtId"
                                :label="district.districtName"
                                :value="district"
                              />
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>

              <v-btn
                block
                color="indigo"
                dark
                class="mt-6"
                @click="next1"
                v-loading="nextLoading"
              >
                下一步
              </v-btn>
            </v-stepper-content>
            
            <v-stepper-content
              step="2"
              class="pa-0"
            >
              <v-sheet class="mt-2 px-6">
                <v-row
                  no-gutters
                  align="baseline"
                >
                  <v-col
                    cols="5"
                  >
                    从业资格类别：
                  </v-col>
                  <v-col
                    cols="7"
                  >
                    <v-bottom-sheet>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="trainType.trainType"
                          hide-details
                          class="no-border mt-0"
                          v-on="on"
                          validate-on-blur
                        />
                      </template>
                      <v-card>
                        <v-card-title>请选择从业资格类别</v-card-title>
                        <v-card-text>
                          <v-radio-group
                            dense
                            v-model="trainType"
                            hide-details
                            class="my-0 pa-2"
                          >
                            <v-radio
                              v-for="trainType in trainTypeList"
                              :key="trainType.trainTypeCode"
                              :label="trainType.trainType"
                              :value="trainType"
                            />
                          </v-radio-group>
                        </v-card-text>
                      </v-card>
                    </v-bottom-sheet>
                  </v-col>
                </v-row>
              </v-sheet>
              <v-sheet class="mt-2 px-6">
                <v-form
                  ref="dataForm2"
                >
                  <v-row
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      培训机构：
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-bottom-sheet>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择"
                            :value="trainOrg.trainOrgName"
                            :rules="rules2.trainOrgId | translation"
                            hide-details
                            class="no-border"
                            v-on="on"
                            validate-on-blur
                          />
                        </template>
                        <v-card>
                          <v-card-title>请选择培训机构</v-card-title>
                          <v-card-text>
                            <v-radio-group
                              dense
                              v-model="trainOrg"
                              hide-details
                              class="my-0 pa-2"
                            >
                              <v-radio
                                v-for="trainOrg in trainOrgs"
                                :key="trainOrg.trainOrgId"
                                :label="trainOrg.trainOrgName"
                                :value="trainOrg"
                              />
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>
                  <v-divider v-if="trainTypeConf.trainFlag === 'Optional'" />
                  <v-row
                    v-if="trainTypeConf.trainFlag === 'Optional'"
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      是否培训：
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-checkbox
                        dense
                        hide-details
                        v-model="wxUser.trainFlag"
                        class="py-3"
                      />
                    </v-col>
                  </v-row>
                  <v-divider v-if="trainModeIf" />
                  <v-row
                    v-if="trainModeIf"
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      培训方式：
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-bottom-sheet>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择"
                            :value="trainMode.name"
                            :rules="rules2.trainMode"
                            hide-details
                            class="no-border"
                            v-on="on"
                            validate-on-blur
                          />
                        </template>
                        <v-card>
                          <v-card-title>请选择培训方式</v-card-title>
                          <v-card-text>
                            <v-radio-group
                              dense
                              v-model="trainMode"
                              hide-details
                              class="my-0 pa-2"
                            >
                              <v-radio
                                v-for="trainMode in trainTypeConf.trainMode"
                                :key="trainMode.code"
                                :label="trainMode.name"
                                :value="trainMode"
                              />
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>

                  <v-divider v-if="courseIf" />
                  <v-row
                    v-if="courseIf"
                    no-gutters
                    align="baseline"
                  >
                    <v-col
                      cols="5"
                    >
                      课程：
                    </v-col>
                    <v-col
                      cols="7"
                    >
                      <v-bottom-sheet>
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            placeholder="请选择"
                            :value="course.courseName"
                            :rules="rules2.courseCode"
                            hide-details
                            class="no-border"
                            v-on="on"
                            validate-on-blur
                          />
                        </template>
                        <v-card>
                          <v-card-title>请选择课程</v-card-title>
                          <v-card-text>
                            <v-radio-group
                              dense
                              v-model="course"
                              hide-details
                              class="my-0 pa-2"
                            >
                              <v-radio
                                v-for="course in courseList"
                                :key="course.courseCode"
                                :label="course.courseName"
                                :value="course"
                              />
                            </v-radio-group>
                          </v-card-text>
                        </v-card>
                      </v-bottom-sheet>
                    </v-col>
                  </v-row>
                </v-form>
              </v-sheet>

              <v-btn
                block
                color="indigo"
                dark
                class="mt-6"
                @click="next2"
                v-loading="nextLoading"
              >
                下一步
              </v-btn>
            </v-stepper-content>
            <v-stepper-content
              step="3"
              class="pa-0"
            >
              <v-row
                dense
                class="mt-2"
              >
                <v-col
                  v-for="(fileConfig, index) in fileConfigs"
                  :key="fileConfig.id"
                  cols="6"
                >
                  <pic-card-upload
                    :file-catalog="fileConfig"
                    @returnFileObj="obj => returnFileObj(obj, index, fileConfig.fileName)"
                  />
                </v-col>
              </v-row>

              <v-btn
                block
                color="indigo"
                dark
                class="mt-6"
                @click="next3"
                v-loading="nextLoading"
              >
                下一步
              </v-btn>
            </v-stepper-content>
            <v-stepper-content
              step="4"
              class="pa-0 white"
            >
              <v-row>
                <v-col
                  align="center"
                  justify="center"
                  cols="12"
                >
                  <v-icon
                    color="green"
                    x-large
                  >
                    mdi-check-circle
                  </v-icon>
                </v-col>
                <v-col
                  align="center"
                  justify="center"
                  class="subtitle-1 font-weight-bold"
                  cols="12"
                >
                  注册成功，等待审核
                </v-col>
              </v-row>
              <bottom-nav />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import api from '@/api'
import { getWxUser } from '@/utils/storageUtils'
import PicCardUpload from '@/components/PicCardUpload'
import BottomNav from '@/components/BottomNav'
export default {
  name: 'EmpReg',
  components: {
    PicCardUpload,
    BottomNav,
  },
  data: () => ({
    step: 1,
    loading: false,
    wxUser: {
      openId: '',
      districtId: undefined,
      name: '',
      idCard: '',
      idType: 1,
      sex: undefined,
      birthDate: undefined,
      nation: undefined,
      phone: '',
      address: '',
      driverClass: '',
      driverFirstDate: '',
      driverArchiveNo: undefined,
      trainTypeCode: '',
      trainFlag: 0,
      trainMode: undefined,
      faceImg: undefined,
      files: [], // 上传的材料集合
      courseCode: undefined, // 课程编码
    },
    rules: {
      idCard: [
        { required: true, message: '请输入身份证号码', type: 'idCard' },
        { max: 18 }
      ],
      phone: [
        { required: true, message: '请输入手机号码', type: 'mobile' },
        { max: 11 }
      ],
      name: [
        { required: true, message: '请输入姓名' },
        { max: 20 }
      ],
      address: [ { max: 255 } ],
      birthDate: [ { required: true, message: '请选择出生日期' }, ],
      sex: [ { required: true, message: '请选择性别' } ],
      districtId: [ { required: true, message: '请选择城市' } ],
    },
    rules2: {
      trainOrgId: [ { required: true, message: '请选择培训机构' } ],
      trainMode: [ v => !((() => this.trainModeShow()) && !v) || '请选择培训方式' ],
      courseCode: [ v => !((() => this.courseShow()) && !v) || '请选择课程' ],
    },
    openDistricts: [],
    district: {
      districtId: undefined,
      districtName: undefined,
      trainTypeList: undefined,
    },
    idcardocrPath: process.env.VUE_APP_BASE_URL + api.file.idcardocr,
    idcardocr: false,
    idcardLoading: false,
    facePath: process.env.VUE_APP_BASE_URL + api.file.compareFace,
    face: false,
    faceLoading: false,
    nextLoading: false,
    driverClassList: ['A1','A2','A3','B1','B2','C1','C2','C3','C4','D','E','F','M','N','P'],
    driverClass: undefined,
    trainTypeConfs: [], // 开通地区培训类型配置
    trainTypeConf: {}, // 学员选择的地区、选择的类别对应的类型配置
    trainTypeList: [], // 学员可报名类型集合
    trainType: {}, // 学员选择报名的类别
    trainOrgs: [], // 驾校
    trainOrg: {}, // 学员选择的驾校
    trainMode: {}, // 学员选择的培训方式
    fileConfigs: [], // 学员选择的类别需上传的材料
    courseList: [], // 可选的网上学习课程
    course: {}, // 学员选择的课程
    districtDisabled: false,
    appId: undefined,
  }),
  watch: {
    district: {
      handler (newValue) {
        this.wxUser.districtId = newValue.districtId
        const trainTypeListStr = newValue.trainTypeList
        if (!trainTypeListStr) {
          this.$msg.warning('地区培训类别未配置')
          return
        }
        this.trainTypeConfs = JSON.parse(trainTypeListStr)
        if (this.trainTypeConfs.length == 0) {
          this.$msg.warning('地区培训类别未配置')
        }
        this.getTrainOrgs()
      },
      deep: true
    },
    driverClass: {
      handler (newValue) {
        this.wxUser.driverClass = newValue.join(',')
      },
      deep: true
    },
    trainType: {
      handler (newValue) {
        this.wxUser.staffId = newValue.staffId
        this.wxUser.qualificationId = newValue.qualificationId
        this.wxUser.trainTypeCode = newValue.trainTypeCode
        this.wxUser.trainType = newValue.trainType
        this.getTrainTypeConf()
      },
      deep: true
    },
    trainMode: {
      handler (newValue) {
        this.wxUser.trainMode = newValue.code
      },
      deep: true
    },
    trainOrg: {
      handler (newValue) {
        this.wxUser.trainOrgId = newValue.trainOrgId
      },
      deep: true
    },
    course: {
      handler (newValue) {
        this.wxUser.courseCode = newValue.courseCode
      },
      deep: true
    },
  },
  computed: {
    trainModeIf () {
      return this.trainModeShow()
    },
    courseIf () {
      return this.courseShow()
    },
  },
  created () {
    const { appId, openId, districtId } = getWxUser()
    if (openId) {
      this.wxUser.openId = openId
      if (districtId) {
        this.wxUser.districtId = districtId * 1
      }
      this.appId = appId
    }
    this.getOpenDistricts()
  },
  methods: {
    getOpenDistricts () {
      this.loading = true
      const eduTypeCode = 1
      this.$http.get(api.openDistrict.index, {params: { eduTypeCode }})
      .then(response => {
        this.openDistricts = response.data
        if (this.wxUser.districtId) {
          this.districtDisabled = true
          const district = this.openDistricts.find(item => item.districtId === this.wxUser.districtId)
          if (!district) {
            this.$msg.warning('当前地区未开通报名')
          } else {
            this.district = district
          }
        }
        this.loading = false
      })
    },
    idCardBefore () {
      this.idcardLoading = true
    },
    idCardUpload (response) {
      this.idcardLoading = false
      if (response.code === 0) {
        const localWxUser = getWxUser()
        Object.assign(this.wxUser, localWxUser, response.data)
        // setWxUser(this.wxUser)
        this.$msg.success(response.msg)
        this.idcardocr = true
      } else {
        this.$msg.warning(response.msg)
      }
    },
    faceBefore () {
      this.faceLoading = true
    },
    faceUpload (response) {
      this.faceLoading = false
      if (response.code === 0) {
        this.$msg.success('面部照片比对通过')
        this.wxUser.faceImg = response.data * 1
        this.face = true
      } else {
        this.$msg.warning(response.msg)
      }
    },
    next1 () {
      if (!this.idcardocr) {
        this.$msg.warning('请拍摄身份证照片（人像面）')
        return
      }
      if (!this.face) {
        this.$msg.warning('请拍摄本人面部照片')
        return
      }
      if (this.$refs.dataForm.validate()) {
        this.nextLoading = true
        // setWxUser(this.wxUser)
        this.getYunzhengInfo()
      }
    },
    getYunzhengInfo () {
      const idCard = this.wxUser.idCard
      const name = this.wxUser.name
      const districtId = this.wxUser.districtId
      const eduTypeCode = 1
      this.$http.get(api.yunzheng.index, { params: { idCard, name, districtId, eduTypeCode } })
      .then(response => {
        this.trainTypeList = response.data
        if (this.trainTypeList && this.trainTypeList.length > 0) {
          this.trainType = this.trainTypeList[0]
        }
        this.nextLoading = false
        this.step++
      })
      .catch(response => {
        this.$msg.error(response.msg || response.message)
        this.nextLoading = false
      })
    },
    getTrainTypeConf () {
      const districtId = this.district.districtId
      const eduTypeCode = 1
      const trainTypeCode = this.trainType.trainTypeCode
      const trainTypeConf = this.trainTypeConfs.find(item => item.trainType.code === trainTypeCode)
      if (!trainTypeConf) {
        this.$msg.warning('地区培训类别未配置')
      } else {
        this.trainTypeConf = trainTypeConf
      }
      this.$http.get(api.trainTypeConfig.index, { params: { districtId, eduTypeCode, trainTypeCode } })
      .then(response => {
        if (response.data.courseList) {
          this.courseList = JSON.parse(response.data.courseList)
        }
        if (this.courseList && this.courseList.length === 1) {
          this.course = this.courseList[0]
        }
      })
    },
    getTrainOrgs () {
      this.$http.get(this.$rp(api.openDistrict.trainOrgs, this.district.districtId))
      .then (response => {
        this.trainOrgs = response.data
        if (this.trainOrgs && this.trainOrgs.length === 1) {
          this.trainOrg = this.trainOrgs[0]
        }
      })
    },
    trainModeShow () {
      return this.trainTypeConf.trainModeOptional === 1 && (this.wxUser.trainFlag || this.trainTypeConf.trainFlag === 'Necessary')
    },
    courseShow () {
      return this.wxUser.trainMode === 'Online' || ((this.wxUser.trainFlag || this.trainTypeConf.trainFlag === 'Necessary') && (this.trainTypeConf.trainModeOptional === 0 && this.trainTypeConf.trainMode && this.trainTypeConf.trainMode[0].code == 'Online'))
    },
    next2 () {
      if (this.$refs.dataForm2.validate()) {
        this.nextLoading = true
        this.getFileConfigs()
      }
    },
    getFileConfigs () {
      const districtId = this.district.districtId
      const eduTypeCode = 1
      const trainTypeCode = this.trainType.trainTypeCode
      this.$http.get(api.fileConfig.index,
        { params: { districtId, eduTypeCode, trainTypeCode }})
      .then(response => {
        this.fileConfigs = response.data
        this.wxUser.files = new Array(this.fileConfigs.length)
        this.nextLoading = false
        this.step++
      })
      .catch(response => {
        this.$msg.error(response.msg || response.message)
        this.nextLoading = false
      })
    },
    returnFileObj (fileObj, index, fileName) {
      this.wxUser.files.splice(index, 1, { fileName, fileType: fileObj.comment, fileId: fileObj.id })
    },
    next3 () {
      this.nextLoading = true
      let isAllUpload = true
      this.fileConfigs.forEach((item, index) => {
        if (item.necessary === 1) {
          if (!this.wxUser.files[index]) {
            isAllUpload = false
            this.nextLoading = false
            this.$msg.warning(`请上传${item.fileName}`)
            throw new Error('跳出循环')
          }
        }
      })
      if (isAllUpload) {
        this.createStudent()
      }
    },
    createStudent () {
      this.wxUser.eduTypeCode = 1
      this.$http.post(api.student.index, this.wxUser).then(() => {
        this.step++
        this.nextLoading = false
      }).catch(response => {
        this.$msg.error(response.msg || response.message)
        this.nextLoading = false
      })
    },
  },
}
</script>
<style>
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
    font-size: 11px;
    color: grey;
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: #E0E0E0 !important;
  }
  .no-border.v-text-field > .v-input__control > .v-input__slot::before {
    border: none;
  }
  .v-text-field.error--text > .v-input__control > .v-input__slot::before {
    border-color: #ff5252 !important;
  }
</style>
