<template>
  <v-app>
    <v-main v-loading="loading">
      <top-title />
      <v-sheet
        :color="$route.meta.color ? $route.meta.color : 'primary lighten-1'"
        height="45"
        class="rounded-b-xl"
      />
      <v-container class="mt-n12">
        <v-carousel
          hide-delimiters
          :show-arrows="false"
          cycle
          height="130"
          interval="3500"
          class="rounded-b-lg"
        >
          <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
          />
        </v-carousel>
        <v-bottom-sheet
          scrollable
          v-model="locationOn"
        >
          <template v-slot:activator="{ on }">
            <v-row
              no-gutters
              class="mt-6"
              v-on="on"
            >
              <v-col
                cols="12"
                class="ml-n2"
              >
                <v-icon>mdi-map-marker</v-icon>{{ GET_CUR_DISTRICT.name ? GET_CUR_DISTRICT.name : locationTip }}<v-icon>mdi-menu-down</v-icon>
              </v-col>
            </v-row>
          </template>
          <v-list
            dense
            subheader
            min-height="150"
          >
            <v-subheader class="pl-1">
              地区直达
              <v-spacer />
              <span
                @click="locationOn = false"
                class="text-body-2"
              >
                确定
              </span>
            </v-subheader>
            <v-list-item-group v-model="districtId">
              <v-divider />
              <template v-for="(district, index) in districtList">
                <v-list-item
                  :key="district.id"
                  color="primary"
                  :value="district.id"
                  @click="selectDistrict(district)"
                  class="d-flex justify-center"
                >
                  {{ district.name }}
                </v-list-item>
                <v-divider
                  v-if="index < districtList.length - 1"
                  :key="'ddivider' + district.id"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-bottom-sheet>
        <v-row
          no-gutters
          class="mt-6"
        >
          <v-col cols="12">
            智慧报名
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-start"
          >
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/AppointmentExamination')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/empreg.png"
                contain
                height="30"
                width="30"
              />
              从业资格
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/conedu-reg-42')"
              style="width: 33%;"
              v-if="(districtId + '').indexOf('42') === 0"
            >
              <v-img
                src="../assets/conedureg.png"
                contain
                height="30"
                width="30"
              />
              继续教育
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/conedu-reg-66')"
              style="width: 33%;"
              v-else-if="(districtId + '').indexOf('66') === 0"
            >
              <v-img
                src="../assets/conedureg.png"
                contain
                height="30"
                width="30"
              />
              继续教育
            </div>
            <div
              v-else
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/ConeduReg')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/conedureg.png"
                contain
                height="30"
                width="30"
              />
              继续教育
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/clearscoreReg')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/clearscorereg.png"
                contain
                height="30"
                width="30"
              />
              清分学习
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-start"
          >
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/EmpResumeReg')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/empresumereg.png"
                contain
                height="30"
                width="30"
              />
              资格恢复
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/EmpResumeReg')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/safetrainreg.png"
                contain
                height="30"
                width="30"
              />
              安全培训
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/Conedu12Reg')"
              style="width: 33%;"
              v-if="districtId === 370900"
            >
              <v-img
                src="../assets/conedu12reg.png"
                contain
                height="30"
                width="30"
              />
              <span class="text-caption">12学时继续教育</span>
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/taxi-patch')"
              style="width: 33%;"
              v-if="districtId === 652200"
            >
              <v-icon size="30">
                mdi-taxi
              </v-icon>
              出租车补学
            </div>
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mt-6"
        >
          <v-col cols="12">
            智慧预约
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-start"
          >
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/AppointmentTrain')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/trainapp.png"
                contain
                height="30"
                width="30"
              />
              培训预约
            </div>
            <div
              class="d-flex flex-column align-center px-4 pt-4"
              @click="to('/AppointmentExamination')"
              style="width: 33%;"
            >
              <v-img
                src="../assets/examapp.png"
                contain
                height="30"
                width="30"
              />
              考试预约
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <bottom-nav />
  </v-app>
</template>

<script>
import api from '@/api'
import TopTitle from '@/components/TopTitle'
import BottomNav from '@/components/BottomNav'
import { getWxUser, setWxUser, setQueryStr } from '@/utils/storageUtils'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    BottomNav,
    TopTitle,
  },
  name: 'Index',
  data: () => ({
    loading: false,
    items: [
      {
        src: './chart1.png',
      },
      {
        src: './chart2.png',
      },
      {
        src: './chart3.png',
      },
    ],
    locationOn: false,
    locationTip: '加载中...',
    districtList: [],
    districtId: undefined,
  }),
  computed: {
    ...mapState([
      'curDistrict'
    ]),
    ...mapGetters([ 'GET_CUR_DISTRICT' ]),
  },
  watch: {
    curDistrict: {
      handler (newValue) {
        this.districtId = newValue.id
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'SET_CUR_DISTRICT',
    ]),
    to (path) {
      this.$router.push({ path })
    },
    getDistricts(districtId) {
      if (districtId) {
        this.loading = true
        this.$http.get(this.$rp(api.openDistrict.list, districtId))
        .then (response => {
          this.districtList = response.data
          this.loading = false
          if (Object.keys(this.GET_CUR_DISTRICT).length === 0) {
            this.locationTip = '请选择'
            this.locationOn = true
            if (this.districtList.length === 1) {
              this.selectDistrict(this.districtList[0])
            }
          }
        })
      }
    },
    selectDistrict (cur) {
      const wxUser = getWxUser() || {}
      wxUser.districtId = cur.id
      setWxUser(wxUser)
      this.locationOn = false
      this.SET_CUR_DISTRICT(cur)
    },
  },
  created () {
    const queryStr = this.$route.query
    setQueryStr(queryStr)
    const { districtId } = queryStr
    const wxUser = getWxUser() || {}
    this.districtId = this.GET_CUR_DISTRICT.id
    Object.assign(wxUser, queryStr, { districtId: this.GET_CUR_DISTRICT.id })
    setWxUser(wxUser)
    this.getDistricts(districtId)
  },
}
</script>
<style>
  .v-image__image--contain {
    background-position: left center !important;
  }
</style>
