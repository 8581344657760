<template>
  <v-app>
    <v-main
      class="grey lighten-4"
      v-loading="loading"
    >
      <v-container>
        <v-stepper
          v-model="step"
          alt-labels
          elevation="0"
          class="grey lighten-4"
        >
          <v-stepper-header class="white elevation-0 pb-2">
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              上传信息
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              从业资格信息
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 3"
              step="3"
            >
              在线支付
            </v-stepper-step>
            <v-divider />
            <v-stepper-step
              :complete="step > 4"
              step="4"
            >
              报名完成
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content
              step="1"
              class="pa-0"
            >
              <v-sheet class="mt-2">
                <v-list>
                  <v-list-item>
                    <v-alert
                      dense
                      outlined
                      color="red"
                      class="text-body-2"
                    >
                      1、请先上传身份证照片页以及本人面部照片
                    </v-alert>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>身份证</v-list-item-title>
                      <v-list-item-subtitle>点击拍摄身份证照片（人像面）</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-upload
                        :action="idcardocrPath"
                        :data="{ openId: wxUser.openId, appId: appId }"
                        :show-file-list="false"
                        accept="image/*"
                        :before-upload="idCardBefore"
                        :on-success="idCardUpload"
                      >
                        <v-btn
                          color="indigo"
                          dark
                          rounded
                          v-loading="idcardLoading"
                        >
                          {{ idcardocr ? '已拍照' : '拍照' }}
                        </v-btn>
                      </el-upload>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>本人面部照片</v-list-item-title>
                      <v-list-item-subtitle>点击拍摄面部照片</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <el-upload
                        :action="facePath"
                        :data="{ openId: wxUser.openId, appId: appId }"
                        :show-file-list="false"
                        accept="image/*"
                        :before-upload="faceBefore"
                        :on-success="faceUpload"
                      >
                        <v-btn
                          color="indigo"
                          dark
                          rounded
                          v-loading="faceLoading"
                        >
                          {{ face ? '已拍照' : '拍照' }}
                        </v-btn>
                      </el-upload>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-sheet>
              <v-sheet class="mt-2 px-6">
                <v-form
                  ref="dataForm"
                >
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div>
                      姓名
                    </div>
                    <div>
                      {{ wxUser.name }}
                    </div>
                  </div>
                  <v-divider />
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div>
                      身份证号
                    </div>
                    <div>
                      {{ wxUser.idCard }}
                    </div>
                  </div>
                  <v-divider />
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div>
                      性别
                    </div>
                    <div>
                      {{ wxUser.sex | sexName }}
                    </div>
                  </div>
                  <v-divider />
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div>
                      出生日期
                    </div>
                    <div>
                      {{ wxUser.birthDate }}
                    </div>
                  </div>
                  <v-divider />
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div style="white-space: nowrap;">
                      住址
                    </div>
                    <div class="pl-1 text-body-2">
                      {{ wxUser.address }}
                    </div>
                  </div>
                  <v-divider />
                  <v-alert
                    dense
                    outlined
                    color="red"
                    class="my-0 text-body-2"
                  >
                    2、输入手机号、车型等信息
                  </v-alert>
                  <v-text-field
                    type="number"
                    suffix="手机号"
                    v-model="wxUser.phone"
                    :rules="rules.phone | translation"
                    hide-details="auto"
                    reverse
                  />
                  <v-bottom-sheet
                    scrollable
                    v-model="driverClassOn"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        suffix="准驾车型"
                        v-model="wxUser.driverClass"
                        :rules="rules.driverClass | translation"
                        hide-details="auto"
                        v-on="on"
                        reverse
                        readonly
                        validate-on-blur
                      />
                    </template>
                    <v-card>
                      <v-subheader>
                        请选择准驾车型（可多选）
                        <v-spacer />
                        <span
                          @click="driverClassOn = false"
                          class="text-body-2"
                        >
                          确定
                        </span>
                      </v-subheader>
                      <v-card-text
                        style="height: 350px;"
                        class="pl-3"
                      >
                        <v-checkbox
                          v-model="driverClass"
                          v-for="key in driverClassList"
                          :key="key"
                          :label="key"
                          :value="key"
                          hide-details
                          multiple
                          class="mt-0 py-1"
                        />
                      </v-card-text>
                    </v-card>
                  </v-bottom-sheet>
                  <div
                    class="py-3 d-flex justify-space-between align-baseline"
                  >
                    <div>
                      城市
                    </div>
                    <div>
                      {{ district.districtName }}
                    </div>
                  </div>
                </v-form>
              </v-sheet>

              <v-btn
                block
                color="indigo"
                dark
                class="mt-6"
                @click="next1"
                v-loading="nextLoading"
              >
                下一步
              </v-btn>
            </v-stepper-content>
            
            <v-stepper-content
              step="2"
              class="pa-0"
            >
              <v-form
                ref="dataForm2"
              >
                <v-sheet class="mt-2 px-6">
                  <v-bottom-sheet v-model="trainTypeOn">
                    <template v-slot:activator="{ on }">
                      <div
                        class="d-flex justify-end"
                        v-on="on"
                      >
                        <v-text-field
                          suffix="从业资格类别"
                          placeholder="单击此处选择类别"
                          :value="trainType.trainType"
                          hide-details="auto"
                          class="mt-0"
                          reverse
                          readonly
                          prepend-inner-icon="mdi-menu-down"
                        />
                      </div>
                    </template>
                    <v-list
                      dense
                      subheader
                      min-height="150"
                    >
                      <v-subheader class="pl-1">
                        请选择从业资格类别
                        <v-spacer />
                        <span
                          @click="trainTypeOn = false"
                          class="text-body-2"
                        >
                          确定
                        </span>
                      </v-subheader>
                      <v-list-item-group
                        v-model="trainType"
                        mandatory
                      >
                        <v-divider />
                        <template v-for="(trainType, index) in trainTypeList">
                          <v-list-item
                            :key="trainType.trainTypeCode"
                            :value="trainType"
                            label
                            color="primary"
                            class="d-flex justify-center"
                          >
                            {{ trainType.trainType }}
                          </v-list-item>
                          <v-divider
                            v-if="index < trainTypeList.length - 1"
                            :key="'typedivider' + trainType.trainTypeCode"
                          />
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-bottom-sheet>
                  <v-text-field
                    suffix="从业资格证号"
                    v-model.trim="wxUser.certificateNo"
                    :rules="rules2.certificateNo | translation"
                    hide-details="auto"
                    reverse
                  />
                  <div
                    class="pt-5 pb-1 d-flex justify-space-between align-baseline"
                    v-if="trainType.orgName"
                  >
                    <div>
                      发证机关：
                    </div>
                    <div>
                      {{ trainType.orgName }}
                    </div>
                  </div>
                  <v-divider v-if="trainType.orgName" />
                  <v-text-field
                    suffix="有效期至"
                    placeholder="快捷输入8位数字"
                    v-model.trim="trainType.expiryDate"
                    :rules="rules2.expiryDate | translation"
                    hide-details="auto"
                    reverse
                    hint="快捷输入8位数字"
                    persistent-hint
                  />
                  <v-select
                    v-show="expanded"
                    :items="stageList"
                    prefix="学习周期"
                    hide-details="auto"
                    v-model="stage"
                    :rules="rules2.stage | translation"
                    hint="请选择学习周期"
                    persistent-hint
                  >
                    <template v-slot:item="{ item }">
                      {{ item[0].substring(0, 4) }} - {{ item[1].substring(0, 4) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item[0].substring(0, 4) }} - {{ item[1].substring(0, 4) }}
                    </template>
                  </v-select>
                </v-sheet>
                <v-sheet class="mt-2 px-6">
                  <div v-show="!entId">
                    <v-bottom-sheet
                      v-model="trainOrgOn"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          suffix="培训机构"
                          placeholder="请选择培训机构"
                          :value="trainOrg.trainOrgName"
                          :rules="rules2.trainOrgId | translation"
                          hide-details="auto"
                          v-on="on"
                          validate-on-blur
                          reverse
                          readonly
                        />
                      </template>
                      <v-list
                        dense
                        subheader
                        min-height="150"
                      >
                        <v-subheader class="pl-4">
                          请选择培训机构
                          <v-spacer />
                          <span
                            @click="trainOrgOn = false"
                            class="text-body-2"
                          >
                            确定
                          </span>
                        </v-subheader>
                        <v-list-item-group
                          mandatory
                          v-model="trainOrg"
                        >
                          <template v-for="(trainOrg, index) in trainOrgs">
                            <v-list-item
                              :key="trainOrg.trainOrgId"
                              :value="trainOrg"
                              label
                              color="primary"
                              text-color="white"
                              class="text-caption"
                            >
                              {{ trainOrg.trainOrgName }}
                            </v-list-item>
                            <v-divider
                              v-if="index < trainOrgs.length - 1"
                              :key="'trainOrgdivider' + trainOrg.trainOrgId"
                            />
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-bottom-sheet>
                  </div>
                  <v-bottom-sheet
                    v-model="regPlaceOn"
                    v-if="!rid"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        suffix="报名地点"
                        placeholder="请选择报名地点"
                        :value="regPlace.regPlaceAddr"
                        :rules="rules2.regPlaceId | translation"
                        hide-details="auto"
                        v-on="on"
                        validate-on-blur
                        reverse
                        readonly
                      />
                    </template>
                    <v-list
                      dense
                      subheader
                      min-height="150"
                    >
                      <v-subheader class="pl-1">
                        请选择报名地点
                        <v-spacer />
                        <span
                          @click="regPlaceOn = false"
                          class="text-body-2"
                        >
                          确定
                        </span>
                      </v-subheader>
                      <v-list-item-group
                        mandatory
                        v-model="regPlace"
                      >
                        <v-divider />
                        <template v-for="(regPlace, index) in regPlaceList">
                          <v-list-item
                            :key="regPlace.regPlaceId"
                            :value="regPlace"
                            color="primary"
                            text-color="white"
                            class="pl-1 text-caption"
                          >
                            {{ regPlace.regPlaceAddr }}
                          </v-list-item>
                          <v-divider
                            v-if="index < regPlaceList.length - 1"
                            :key="'regdivider' + regPlace.regPlaceId"
                          />
                        </template>
                      </v-list-item-group>
                    </v-list>
                  </v-bottom-sheet>
                  <!-- <div
                    v-else
                    class="pt-3 pb-1"
                  >
                    <div class="d-flex justify-space-between align-baseline">
                      <div>
                        推广人
                      </div>
                      <div>
                        {{ regPlace.regPlaceName }}
                      </div>
                    </div>
                    <v-divider />
                  </div> -->
                  <div
                    v-if="trainTypeConf.trainFlag === 'Optional'"
                    class="d-flex justify-space-between align-baseline"
                  >
                    是否培训：
                    <v-checkbox
                      dense
                      hide-details
                      v-model="wxUser.trainFlag"
                      class="py-3"
                    />
                  </div>
                  <v-divider v-if="trainTypeConf.trainFlag === 'Optional'" />
                  <div v-if="trainModeIf">
                    <v-bottom-sheet
                      v-model="trainModeOn"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          suffix="培训方式"
                          placeholder="请选择培训方式"
                          :value="trainMode.name"
                          :rules="rules2.trainMode"
                          hide-details="auto"
                          v-on="on"
                          validate-on-blur
                          reverse
                          readonly
                        />
                      </template>
                      <v-list
                        dense
                        subheader
                      >
                        <v-subheader class="pl-1">
                          请选择培训方式
                          <v-spacer />
                          <span
                            @click="trainModeOn = false"
                            class="text-body-2"
                          >
                            确定
                          </span>
                        </v-subheader>
                        <v-list-item-group
                          mandatory
                          v-model="trainMode"
                        >
                          <template v-for="(trainMode, index) in trainTypeConf.trainMode">
                            <v-list-item
                              :key="trainMode.code"
                              :value="trainMode"
                              color="primary"
                              text-color="white"
                              class="pl-1 text-caption"
                            >
                              {{ trainMode.name }}
                            </v-list-item>
                            <v-divider
                              v-if="index < trainTypeConf.trainMode.length - 1"
                              :key="'tmdivider' + trainMode.code"
                            />
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-bottom-sheet>
                  </div>

                  <div v-if="courseIf">
                    <v-bottom-sheet
                      v-model="courseOn"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          suffix="课程"
                          placeholder="请选择课程"
                          :value="course.courseName"
                          :rules="rules2.courseCode"
                          hide-details="auto"
                          v-on="on"
                          validate-on-blur
                          reverse
                          readonly
                        />
                      </template>
                      <v-list
                        dense
                        subheader
                        min-height="150"
                      >
                        <v-subheader class="pl-1">
                          请选择课程
                          <v-spacer />
                          <span
                            @click="courseOn = false"
                            class="text-body-2"
                          >
                            确定
                          </span>
                        </v-subheader>
                        <v-list-item-group
                          mandatory
                          v-model="course"
                        >
                          <v-divider />
                          <template v-for="(course, index) in courseList">
                            <v-list-item
                              :key="course.courseCode"
                              :value="course"
                              color="primary"
                              class="d-flex justify-center text-caption"
                            >
                              {{ course.courseName }}
                            </v-list-item>
                            <v-divider
                              v-if="index < courseList.length - 1"
                              :key="'csdivider' + course.courseCode"
                            />
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-bottom-sheet>
                  </div>
                  <v-text-field
                    suffix="工作单位"
                    placeholder="请输入工作单位"
                    v-model="wxUser.workUnit"
                    reverse
                  />
                </v-sheet>
              </v-form>
              <v-btn
                block
                color="indigo"
                dark
                class="mt-6"
                @click="next2"
                v-loading="nextLoading"
              >
                下一步
              </v-btn>
            </v-stepper-content>
            <v-stepper-content
              step="3"
              class="pa-0"
            >
              <v-sheet class="mt-2 px-6">
                <v-row
                  no-gutters
                  align="baseline"
                  class="py-3"
                >
                  <v-col cols="5">
                    姓 名：
                  </v-col>
                  <v-col cols="7">
                    {{ wxUser.name }}
                  </v-col>
                </v-row>
                <v-divider />
                <v-row
                  no-gutters
                  align="baseline"
                  class="py-3"
                >
                  <v-col cols="5">
                    从业资格类别：
                  </v-col>
                  <v-col cols="7">
                    {{ trainType.trainType }}
                  </v-col>
                </v-row>
              </v-sheet>

              <v-sheet class="mt-2 px-6">
                <v-row
                  no-gutters
                  align="baseline"
                >
                  <v-col
                    cols="12"
                    class="pt-1"
                  >
                    购买课程
                  </v-col>
                </v-row>
                <v-divider />
                <v-row
                  no-gutters
                  align="baseline"
                  class="py-3"
                >
                  <v-col cols="12">
                    <template v-if="courseIf">
                      {{ course.courseName }}
                    </template>
                    <template v-if="classroomIf">
                      课堂培训费
                    </template>
                    <template v-if="(courseIf || classroomIf) && classroomTest">
                      + 
                    </template>
                    <template v-if="classroomTest">
                      考试费
                    </template>
                  </v-col>
                  <v-col
                    cols="12"
                    class="red--text"
                  >
                    ¥ {{ payConfig.amount }}
                  </v-col>
                </v-row>
              </v-sheet>

              <v-footer
                color="white"
                fixed
              >
                <v-spacer /><span class="text-body-2">待支付：</span><span class="text-body-2 red--text mr-1">¥</span><span class="text-h6 red--text mr-2">{{ payConfig.amount }}</span>
                <v-btn
                  rounded
                  color="yellow accent-4"
                  @click="pay"
                  v-loading="nextLoading"
                >
                  立即支付
                </v-btn>
              </v-footer>
            </v-stepper-content>
            <v-stepper-content
              step="4"
              class="pa-0 white"
            >
              <v-row>
                <v-col
                  align="center"
                  justify="center"
                  cols="12"
                >
                  <v-icon
                    color="green"
                    x-large
                  >
                    mdi-check-circle
                  </v-icon>
                </v-col>
                <v-col
                  align="center"
                  justify="center"
                  class="subtitle-1 font-weight-bold"
                  cols="12"
                >
                  您已报名成功，请在
                  <a
                    href="javascript:void(0);"
                    @click="toStudy"
                  >
                    公众号学习
                  </a>
                  或<a href="https://a.app.qq.com/o/simple.jsp?pkgname=com.bjtongan.anjia365.jxjy">点击链接</a>下载app登录学习，登录初始密码为123456
                </v-col>
              </v-row>
              <bottom-nav />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>

      <v-dialog
        v-model="confirmDialog"
        persistent
        width="70%"
      >
        <v-card
          rounded="lg"
        >
          <v-toolbar
            flat
            dense
          >
            <v-toolbar-title class="font-weight-bold text-body-2">
              温馨提示
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              icon
              @click="confirmDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pa-0 text-center">
            <div class="px-3 pb-3">
              您选择的类别是<span class="font-weight-bold">{{ wxUser.trainType }}</span>，
              周期是<span class="font-weight-bold">{{ wxUser.stageStartDate }} 至 {{ wxUser.stageEndDate }}</span>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="primary"
              text
              @click="confirmAdd"
              v-loading="confirmLoading"
              block
            >
              确认
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import api from '@/api'
import { getWxUser } from '@/utils/storageUtils'
import BottomNav from '@/components/BottomNav'
import { validateyyyy_mm_dd } from '@/utils/validate'
export default {
  name: 'ConeduReg42',
  components: {
    BottomNav,
  },
  data: () => ({
    step: 1,
    loading: false,
    wxUser: {
      openId: '',
      districtId: undefined,
      name: '',
      idCard: '',
      idType: 1,
      sex: undefined,
      birthDate: undefined,
      nation: undefined,
      phone: '',
      address: '',
      driverClass: '',
      driverFirstDate: '',
      driverArchiveNo: undefined,
      eduTypeCode: 2,
      trainTypeCode: '',
      certificateNo: undefined,
      certificateFirstDate: undefined,
      startDate: undefined,
      expiryDate: undefined,
      orgName: undefined,
      stageStartDate: undefined,
      stageEndDate: undefined,
      trainFlag: 0,
      trainMode: undefined,
      faceImg: undefined,
      files: [], // 上传的材料集合
      courseCode: undefined, // 课程编码
    },
    rules: {
      idCard: [
        { required: true, message: '请输入身份证号码', type: 'idCard' },
        { max: 18 }
      ],
      phone: [
        { required: true, message: '请输入手机号码', type: 'mobile' },
        { max: 11 }
      ],
      name: [
        { required: true, message: '请输入姓名' },
        { max: 20 }
      ],
      address: [ { max: 255 } ],
      birthDate: [ { required: true, message: '请选择出生日期' }, ],
      driverClass: [ { required: true, message: '请选择准驾车型' }, ],
      driverFirstDate: [ { max: 10, type: 'yyyy_mm_dd' } ],
      sex: [ { required: true, message: '请选择性别' } ],
      districtId: [ { required: true, message: '请选择城市' } ],
    },
    rules2: {
      certificateNo: [ { required: true, message: '请输入从业资格证号' } ],
      expiryDate: [ { required: true, message: '请输入有效期至' }, { max: 10, type: 'yyyy_mm_dd' } ],
      certificateFirstDate: [ { required: true, message: '请输入初次领证日期' }, { max: 10, type: 'yyyy_mm_dd' } ],
      stageStartDate: [ { required: true, message: '请输入阶段起始日期' }, { max: 10, type: 'yyyy_mm_dd' } ],
      trainOrgId: [ { required: true, message: '请选择培训机构' } ],
      regPlaceId: [ { required: true, message: '请选择报名地点' } ],
      trainMode: [ v => !((() => this.trainModeShow()) && !v) || '请选择培训方式' ],
      courseCode: [ v => !((() => this.courseShow()) && !v) || '请选择课程' ],
      stage: [ { type: 'len', message: '请选择学习周期' } ],
    },
    openDistricts: [],
    district: {
      districtId: undefined,
      districtName: undefined,
      trainTypeList: undefined,
    },
    idcardocrPath: process.env.VUE_APP_BASE_URL + api.file.idcardocr,
    idcardocr: false,
    idcardLoading: false,
    facePath: process.env.VUE_APP_BASE_URL + api.file.compareFace,
    face: false,
    faceLoading: false,
    nextLoading: false,
    driverClassList: ['A1','A2','A3','B1','B2','C1','C2','C3','C4','D','E','F','M','N','P'],
    driverClass: undefined,
    trainTypeConfs: [], // 开通地区培训类型配置
    trainTypeConf: {}, // 学员选择的地区、选择的类别对应的类型配置
    trainTypeList: [], // 学员可报名类型集合
    trainType: {
      trainTypeCode: undefined,
      certificateFirstDate: undefined,
    }, // 学员选择报名的类别
    trainOrgs: [], // 驾校
    trainOrg: {}, // 学员选择的驾校
    trainMode: {}, // 学员选择的培训方式
    fileConfigs: [], // 学员选择的类别需上传的材料
    courseList: [], // 可选的网上学习课程
    course: {}, // 学员选择的课程
    payConfig: {}, // 支付配置
    expanded: false, // 展开从业资格详细信息
    stuId: undefined, // 保存学员后的学员ID
    appId: undefined, // 微信公众号appid
    regPlaceList: [],
    regPlace: {}, // 学员选择的报名点
    driverClassOn: false,
    trainTypeOn: false,
    trainOrgOn: false,
    regPlaceOn: false,
    trainModeOn: false,
    courseOn: false,
    confirmDialog: false,
    confirmLoading: false,
    entId: undefined, // 默认企业Id，不显示
    rid: undefined, // 默认报名点Id，不可更改
    stageList: [], // 可选择周期集合
    stage: [], // 选择的周期
  }),
  watch: {
    district: {
      handler (newValue) {
        this.wxUser.districtId = newValue.districtId
        const trainTypeListStr = newValue.trainTypeList
        if (!trainTypeListStr) {
          this.$msg.warning('地区培训类别未配置')
          return
        }
        this.trainTypeConfs = JSON.parse(trainTypeListStr)
        if (this.trainTypeConfs.length == 0) {
          this.$msg.warning('地区培训类别未配置')
        }
        this.getTrainOrgs()
      },
      deep: true
    },
    driverClass: {
      handler (newValue) {
        this.wxUser.driverClass = newValue.join(',')
      },
      deep: true
    },
    'wxUser.driverFirstDate' (newValue) {
      if (newValue) {
        if (newValue.length === 0) {
          this.wxUser.driverFirstDate = undefined
        } else if (newValue.length === 8 && newValue.indexOf('-') < 0) {
          this.wxUser.driverFirstDate = this.$dateFormat(newValue)
        }
      }
    },
    'trainType.trainTypeCode' () {
      this.wxUser.staffId = this.trainType.staffId
      this.wxUser.qualificationId = this.trainType.qualificationId
      this.wxUser.trainTypeCode = this.trainType.trainTypeCode
      this.wxUser.trainType = this.trainType.trainType
      this.wxUser.certificateNo = this.trainType.certificateNo
      if (!this.wxUser.certificateNo) {
        this.wxUser.certificateNo = this.wxUser.idCard
      }
      const certificateFirstDate = this.trainType.certificateFirstDate
      this.wxUser.certificateFirstDate = certificateFirstDate
      this.wxUser.startDate = this.trainType.startDate
      this.wxUser.expiryDate = this.trainType.expiryDate
      this.wxUser.orgName = this.trainType.orgName
      this.courseList = []
      this.course = {}
      this.stage = []
      this.getTrainTypeConf()
    },
    'trainType.expiryDate' (newValue) {
      this.calcStage(newValue)
    },
    stage: {
      handler (newValue) {
        if (newValue && newValue instanceof Array && newValue.length === 2) {
          this.wxUser.stageStartDate = newValue[0]
          this.wxUser.stageEndDate = newValue[1]
        }
      },
      deep: true
    },
    trainMode: {
      handler (newValue) {
        this.wxUser.trainMode = newValue.code
      },
      deep: true
    },
    trainOrg: {
      handler (newValue) {
        this.wxUser.trainOrgId = newValue.trainOrgId
        this.getRegPlaceList()
      },
      deep: true
    },
    regPlace: {
      handler (newValue) {
        if (newValue) {
          this.wxUser.regPlaceId = newValue.regPlaceId
        }
      },
      deep: true
    },
    course: {
      handler (newValue) {
        this.wxUser.courseCode = newValue.courseCode
      },
      deep: true
    },
  },
  computed: {
    trainModeIf () {
      return this.trainModeShow()
    },
    courseIf () {
      return this.courseShow()
    },
    classroomIf () {
      return (this.wxUser.trainFlag || this.trainTypeConf.trainFlag === 'Necessary') && (this.wxUser.trainMode === 'Classroom' || (this.trainTypeConf.trainModeOptional === 0 && this.trainTypeConf.trainMode && this.trainTypeConf.trainMode[0].code == 'Classroom'))
    },
    classroomTest () {
      return this.trainTypeConf.examFlag === 1 && this.trainTypeConf.examMode.code === 'Classroom'
    },
  },
  created () {
    const { appId, openId, districtId, entId, rid } = getWxUser()
    if (openId) {
      this.wxUser.openId = openId
      if (districtId) {
        this.wxUser.districtId = districtId * 1
      }
      this.appId = appId
      this.entId = entId
      this.rid = rid
    }
    this.getOpenDistricts()
  },
  methods: {
    toStudy () {
      this.$router.push({ path: '/Study' })
    },
    getOpenDistricts () {
      this.loading = true
      const eduTypeCode = this.wxUser.eduTypeCode
      this.$http.get(api.openDistrict.index, {params: { eduTypeCode }})
      .then(response => {
        this.openDistricts = response.data
        if (this.wxUser.districtId) {
          const district = this.openDistricts.find(item => item.districtId === this.wxUser.districtId)
          if (!district) {
            this.$msg.warning('当前地区未开通报名')
          } else {
            this.district = district
          }
        }
        this.loading = false
      })
    },
    idCardBefore () {
      this.idcardLoading = true
    },
    idCardUpload (response) {
      this.idcardLoading = false
      if (response.code === 0) {
        const localWxUser = getWxUser()
        Object.assign(this.wxUser, localWxUser, response.data)
        // setWxUser(this.wxUser)
        this.$msg.success(response.msg)
        this.idcardocr = true
      } else {
        this.$msg.warning(response.msg)
      }
    },
    faceBefore () {
      this.faceLoading = true
    },
    faceUpload (response) {
      this.faceLoading = false
      if (response.code === 0) {
        this.$msg.success('面部照片比对通过')
        this.wxUser.faceImg = response.data * 1
        this.face = true
      } else {
        this.$msg.warning(response.msg)
      }
    },
    next1 () {
      this.nextLoading = true
      if (!this.idcardocr) {
        this.$msg.warning('请拍摄身份证照片（人像面）')
        this.nextLoading = false
        return
      }
      if (!this.face) {
        this.$msg.warning('请拍摄本人面部照片')
        this.nextLoading = false
        return
      }
      if (this.$refs.dataForm.validate()) {
        // setWxUser(this.wxUser)
        this.getYunzhengInfo()
      } else {
        this.nextLoading = false
      }
    },
    getYunzhengInfo () {
      let idCard = this.wxUser.idCard
      let name = this.wxUser.name
      let districtId = this.wxUser.districtId
      const eduTypeCode = this.wxUser.eduTypeCode
      this.$http.get(api.yunzheng.index, { params: { idCard, name, districtId, eduTypeCode } })
      .then(response => {
        this.trainTypeList = response.data
        this.updateWxUser()
        this.nextLoading = false
        this.step++
      })
      .catch(() => {
        this.nextLoading = false
      })
    },
    updateWxUser () {
      this.$http.put(this.$rp(api.wxUser.item, this.wxUser.openId), this.wxUser)
    },
    calcStage (newValue) {
      this.stage = []
      if (newValue) {
        if (newValue.length === 0) {
          this.wxUser.expiryDate = undefined
          this.trainType.expiryDate = undefined
        } else if (newValue.length === 8 && newValue.indexOf('-') < 0) {
          this.wxUser.expiryDate = this.$dateFormat(newValue)
          this.trainType.expiryDate = this.wxUser.expiryDate
        } else {
          this.wxUser.expiryDate = newValue
        }
        const years = this.trainTypeConf.years
        if (validateyyyy_mm_dd(newValue) && years) {
          const firstDate = this.$subtractYear(this.wxUser.expiryDate)
          const firstMMdd = firstDate.substring(4)
          this.trainType.certificateFirstDate = firstDate
          this.wxUser.certificateFirstDate = firstDate
          const oddEven = this.$is_odd_even_year(firstDate)
          if (oddEven !== 2) {
            let fromYear = 2020 + oddEven
            this.stageList = [
              [ fromYear + firstMMdd, (fromYear + years) + firstMMdd ],
              [ (fromYear + years) + firstMMdd, (fromYear + years * 2) + firstMMdd ],
              [ (fromYear + years * 2) + firstMMdd, (fromYear + years * 3) + firstMMdd ]
            ]
          }
          this.expanded = true
        }
      }
    },
    getTrainTypeConf () {
      const districtId = this.district.districtId
      const eduTypeCode = this.wxUser.eduTypeCode
      const trainTypeCode = this.trainType.trainTypeCode
      const trainTypeConf = this.trainTypeConfs.find(item => item.trainType.code === trainTypeCode)
      if (!trainTypeConf) {
        this.$msg.warning('地区培训类别未配置')
      } else {
        this.trainTypeConf = trainTypeConf
      }
      this.$http.get(api.trainTypeConfig.index, { params: { districtId, eduTypeCode, trainTypeCode } })
      .then(response => {
        if (response.data.courseList) {
          this.courseList = JSON.parse(response.data.courseList)
        }
        if (this.courseList && this.courseList.length === 1) {
          this.course = this.courseList[0]
        }
      })
    },
    getTrainOrgs () {
      this.$http.get(this.$rp(api.openDistrict.trainOrgs, this.district.districtId))
      .then (response => {
        this.trainOrgs = response.data
        if (this.trainOrgs) {
          if (this.entId) {
            this.trainOrg = this.trainOrgs.find(item => item.trainOrgId === this.entId)
          } else {
            if (this.trainOrgs.length === 1) {
              this.trainOrg = this.trainOrgs[0]
            }
          }
        }
      })
    },
    getRegPlaceList () {
      const districtId = this.district.districtId
      const trainOrgId = this.trainOrg.trainOrgId
      this.$http.get(this.$rp(api.openDistrict.regPlaces, districtId, trainOrgId))
      .then (response => {
        this.regPlaceList = response.data
        if (this.regPlaceList) {
          if (this.rid) {
            this.regPlace = this.regPlaceList.find(item => item.regPlaceId === this.rid)
          } else {
            if (this.regPlaceList.length === 1) {
              this.regPlace = this.regPlaceList[0]
            }
          }
        }
      })
    },
    trainModeShow () {
      return this.trainTypeConf.trainModeOptional === 1 && (this.wxUser.trainFlag || this.trainTypeConf.trainFlag === 'Necessary')
    },
    courseShow () {
      return this.wxUser.trainMode === 'Online' || ((this.wxUser.trainFlag || this.trainTypeConf.trainFlag === 'Necessary') && (this.trainTypeConf.trainModeOptional === 0 && this.trainTypeConf.trainMode && this.trainTypeConf.trainMode[0].code == 'Online'))
    },
    next2 () {
      this.nextLoading = true
      if (this.$refs.dataForm2.validate()) {
        if (!this.wxUser.certificateNo) {
          this.$msg.warning('请输入从业资格证号')
          this.nextLoading = false
          return
        }
        if (!this.wxUser.trainTypeCode) {
          this.$msg.warning('请选择从业资格类别')
          this.nextLoading = false
          return
        }
        this.confirmDialog = true
      } 
      this.nextLoading = false
    },
    confirmAdd() {
      this.confirmLoading = true
      this.updateWxUser()
      this.createStudent()
    },
    getFileConfigs () {
      const districtId = this.district.districtId
      const eduTypeCode = this.wxUser.eduTypeCode
      const trainTypeCode = this.trainType.trainTypeCode
      this.$http.get(api.fileConfig.index,
        { params: { districtId, eduTypeCode, trainTypeCode }})
      .then(response => {
        this.fileConfigs = response.data
        this.wxUser.files = new Array(this.fileConfigs.length)
        this.nextLoading = false
        this.step++
      })
      .catch(() => {
        this.nextLoading = false
      })
    },
    returnFileObj (fileObj, index, fileName) {
      this.wxUser.files.splice(index, 1, { fileName, fileType: fileObj.comment, fileId: fileObj.id })
    },
    next3 () {
      this.nextLoading = true
      let isAllUpload = true
      this.fileConfigs.forEach((item, index) => {
        if (item.necessary === 1) {
          if (!this.wxUser.files[index]) {
            isAllUpload = false
            this.nextLoading = false
            this.$msg.warning(`请上传${item.fileName}`)
            throw new Error('跳出循环')
          }
        }
      })
      if (isAllUpload) {
        this.createStudent()
      }
    },
    createStudent () {
      this.$http.post(api.student.index, this.wxUser).then(response => {
        this.stuId = response.data.id
        this.getPayConfig()
      }).catch(() => {
        this.nextLoading = false
        this.confirmDialog = false
        this.confirmLoading = false
      })
    },
    getPayConfig () {
      const stuId = this.stuId
      this.$http.get(api.payConfig.index, { params: { stuId } })
      .then(response => {
        this.confirmDialog = false
        this.payConfig = response.data
        this.step++
        this.confirmLoading = false
      })
    },
    pay () {
      this.nextLoading = true
      const stuId = this.stuId
      this.$http.get(api.pay.pay, { params: { stuId } }).then(response => {
        const payResponse = response.data
        const that = this
        window.WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          {
              "appId": payResponse.appId, //公众号名称，由商户传入
              "timeStamp": payResponse.timeStamp, //时间戳，自1970年以来的秒数
              "nonceStr": payResponse.nonceStr, //随机串
              "package": payResponse.packageValue,
              "signType": payResponse.signType, //微信签名方式：
              "paySign": payResponse.paySign //微信签名
          },
          res => {
            if (res.err_msg === "get_brand_wcpay_request:ok" ) {
              that.$msg.success('支付成功')
              that.step++
              this.nextLoading = false
            } else {
              that.$msg.error('支付失败')
            }
          }
        )
      }).catch(() => {
        this.nextLoading = false
      })
    },
  },
}
</script>
<style>
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
    font-size: 11px;
    color: grey;
  }
  .theme--light.v-text-field > .v-input__control > .v-input__slot::before {
    border-color: #E0E0E0 !important;
  }
  .no-border.v-text-field > .v-input__control > .v-input__slot::before {
    border: none;
  }
  .v-text-field.error--text > .v-input__control > .v-input__slot::before {
    border-color: #ff5252 !important;
  }
</style>
