import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import zhHans from 'vuetify/es5/locale/zh-Hans'
import { Loading, MessageBox, Message, Upload } from 'element-ui'

Vue.use(Vuetify)
Vue.use(Loading.directive)
Vue.use(Upload)
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$msg = Message

export default new Vuetify({
  lang: {
    locales: { zhHans },
    current: 'zhHans'
  },
});
