<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="70%"
  >
    <v-card
      rounded="lg"
    >
      <v-toolbar
        flat
        dense
      >
        <v-toolbar-title class="font-weight-bold text-body-2">
          {{ title }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-6 text-center">
        <slot>{{ content }}</slot>
      </v-card-text>
      <v-divider />
      <v-card-actions class="d-flex justify-space-around pb-4">
        <v-btn
          :color="yesBtnColor"
          :outlined="outlined"
          @click="yes"
          v-loading="yesLoading"
        >
          {{ yesBtn }}
        </v-btn>
        <v-btn
          :color="noBtnColor"
          :outlined="outlined"
          @click="no"
          v-loading="noLoading"
        >
          {{ noBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'Confirm',
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: '温馨提示'
    },
    content: {
      type: String,
      required: false,
      default: ''
    },
    outlined: {
      type: Boolean,
      required: false,
      default: true
    },
    yesBtn: {
      type: String,
      required: false,
      default: '确认'
    },
    yesBtnColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    noBtn: {
      type: String,
      required: false,
      default: '取消'
    },
    noBtnColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    yesLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    noLoading: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  watch: {
    show(newValue) {
      this.dialog = newValue
    },
    dialog(newVal) {
      this.$emit('update:show', newVal)
    },
  },
  data: () => ({
    dialog: false, // 弹出框开启关闭控制
  }),
  methods: {
    yes() {
      this.$emit('yes')
    },
    no() {
      this.$emit('no')
    },
  },
}
</script>
