<template>
  <div>
    <top-title />
    <v-sheet
      color="primary lighten-1"
      height="100"
      class="d-flex align-center"
    >
      <v-avatar class="ml-4">
        <img
          :src="info.headImgUrl"
          :alt="info.nickname"
        >
      </v-avatar>
      <span class="ml-2 white--text">{{ info.nickname }}</span>
      <div
        class="black--text ml-2"
        @click="toBind"
      >
        绑定
      </div>
      <div
        class="black--text ml-2"
        @click="toUnbind"
      >
        解绑
      </div>
    </v-sheet>
  </div>
</template>
<script>
import api from '@/api'
import TopTitle from '@/components/TopTitle'
import { getWxUser, setMyInfo, getMyInfo } from '@/utils/storageUtils'
export default {
  name: 'MyTopTitle',
  components: {
    TopTitle,
  },
  data: () => ({
    info: {
      nickname: undefined,
      headImgUrl: './cat.jpg',
    },
  }),
  created() {
    this.getInfo()
  },
  methods: {
    getInfo () {
      const myInfo = getMyInfo() || {}
      if (Object.keys(myInfo).length !== 0) {
        this.info = myInfo
        return
      }
      const wxUser = getWxUser()
      const openId = wxUser.openId
      this.$http.get(this.$rp(api.wxUser.item, openId))
      .then(response => {
        this.info = response.data
        setMyInfo(this.info)
      })
    },
    toBind () {
      this.$router.push({ path: '/bindPerson' })
    },
    toUnbind () {
      this.$router.push({ path: '/unbind' })
    },
  },
}
</script>
