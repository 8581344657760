import moment from 'moment'
import { validateyyyy_mm_dd } from './validate'

function repacePlaceHolder () {
  var args = Array.prototype.slice.call(arguments)
  if (args.length === 0) {
    throw new Error('参数不合法')
  }
  if (args.length === 1) {
    return args[0]
  }
  let orignStr = args.shift()
  args.forEach((item, index) => {
    orignStr = orignStr.replace(new RegExp("\\{" + index + "\\}", "g"), item)
  })
  return orignStr
}

/**
 * 采用正则表达式获取地址栏参数
 * @param name 参数名称
 * @return 返回参数值
 */
function getQueryString (name) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
    const r = getQueryParamStr().match(reg)
    if (r && r.length >= 3) {
        return r[2]
    }
    return ""
}

/**
 * 获取地址栏参数字符串
 * @return 形如：a=123&b=234&c=345
 */
function getQueryParamStr () {
    return decodeURIComponent(window.location.search.substr(1))
}

/**
 * 获取地址栏参数字符串
 * @return 形如：{key1: value1, key2: value2}
 */
function getQueryParamJson () {
    //获取url中的params
    const search = window.location.search.substring(1)
    //params转换object(javascript)
    return search ? JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        }) : {}
}

function dateFormat (dateStr) {
  if (dateStr && dateStr.length === 8 && dateStr.indexOf('-') < 0) {
    return dateStr.substring(0, 4) + '-' + dateStr.substring(4, 6) + '-' + dateStr.substring(6)
  } else {
    return undefined
  }
}

function getStageStartDate(firstDate, years) {
  if (firstDate && validateyyyy_mm_dd(firstDate) && firstDate.length === 10 && firstDate.indexOf('-') > 0 && years) {
    const yearsInt = parseInt(years)
    const firstDateMoment = moment(firstDate)
    const firstYear = firstDateMoment.year()
    const firstMonth = firstDateMoment.month()
    const firstDay = firstDateMoment.date()
    const curMoment = moment()
    const curYear = curMoment.year()
    const remainder =  (curYear - firstYear) % yearsInt
    if (remainder === 0) {
      if (moment().month(firstMonth).date(firstDay).isSameOrBefore(curMoment)) {
        return curMoment.month(firstMonth).date(firstDay).format('YYYY-MM-DD')
      } else {
        return curMoment.subtract(yearsInt, 'y').month(firstMonth).date(firstDay).format('YYYY-MM-DD')
      }
    }
    return curMoment.subtract(remainder, 'y').month(firstMonth).date(firstDay).format('YYYY-MM-DD')
  } else {
    return undefined
  }
}

function getStageEndDate (dateStr, years) {
  if (dateStr && dateStr.length === 10 && dateStr.indexOf('-') > 0 && years) {
    return moment(dateStr).add(parseInt(years), 'y').subtract(1, 'd').format('YYYY-MM-DD')
  } else {
    return undefined
  }
}

function subtractYear(dateStr, yearsInt = 6) {
  if (dateStr && dateStr.length === 10 && dateStr.indexOf('-') > 0 && yearsInt) {
    return moment(dateStr).subtract(yearsInt, 'y').format('YYYY-MM-DD')
  }
  return undefined
}

/**
 * 判断日期是奇偶年
 * @param {*} dateStr yyyy-MM-dd
 * @returns 0 偶数年， 1 奇数年，2 格式有误
 */
function is_odd_even_year(dateStr) {
  if (dateStr && validateyyyy_mm_dd(dateStr)) {
    return parseInt(dateStr.substring(0, 4)) % 2
  }
  return 2
}

export default {
  install: function (Vue) {
    /*
     * 替换字符串中的占位符，如{0} {1} {2}
     */
    Vue.prototype.$rp = repacePlaceHolder
    Vue.prototype.$qp = (name) => {
      if (name) {
        return getQueryString(name)
      } else {
        getQueryParamStr()
      }
    }
    Vue.prototype.$qp_json = getQueryParamJson
    Vue.prototype.is_weixin = () => {
      const ua = window.navigator.userAgent.toLowerCase()
      return ua.match(/MicroMessenger/i) == 'micromessenger'
    }
    Vue.prototype.is_android = () => {
      const ua = window.navigator.userAgent
      return ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1
    }
    Vue.prototype.$dateFormat = dateFormat
    Vue.prototype.$getStageStartDate = getStageStartDate
    Vue.prototype.$getStageEndDate = getStageEndDate
    Vue.prototype.$subtractYear = subtractYear
    Vue.prototype.$is_odd_even_year = is_odd_even_year
  }
}
