<template>
  <el-upload
    class="avatar-uploader"
    name="files"
    :data="{ fileType: fileCatalog.fileCode, appId: appId }"
    :action="baseUrl + 'sys/files/upload'"
    accept="image/*"
    list-type="picture-card"
    with-credentials
    :on-success="uploadSuccess"
    :show-file-list="false"
    :before-upload="beforeUpload"
  >
    <v-img
      v-if="imgUrl"
      :src="imgUrl"
      height="150"
      width="100%"
    />
    <template
      v-else
    >
      <v-sheet
        height="150"
        elevation="0"
        rounded="xl"
        class="d-flex flex-column justify-center align-center"
      >
        <v-icon>
          mdi-camera
        </v-icon>
        <div>
          <span
            v-if="fileCatalog.necessary === 1"
            class="red--text"
          >
            *
          </span>
          {{ fileCatalog.fileName }}
        </div>
      </v-sheet>
    </template>
  </el-upload>
</template>

<script>
import { getWxUser } from '@/utils/storageUtils'
export default {
  name: 'PicCardUpload',
  props: {
    fileCatalog: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data () {
    return {
      imgUrl: '',
      baseUrl: process.env.VUE_APP_BASE_URL,
      appId: undefined,
    }
  },
  created () {
    const { appId } = getWxUser()
    this.appId = appId
  },
  methods: {
    beforeUpload (file) {
      const isLt500k = file.size / 1024 /1024 < 100
      if (!isLt500k) {
        this.$msg.error('上传大小不能超过100MB!');
      }
      return isLt500k
    },
    uploadSuccess (response) {
      const fileObj = response.data[0]
      this.imgUrl = fileObj.url
      this.$emit('returnFileObj', fileObj)
    },
  },
}
</script>
<style>
  .avatar-uploader .el-upload {
    border: none;
    width: 100% !important;
    height: 100% !important;
    background-color: white;
  }
  .el-upload--picture-card {
    line-height: 20px !important;
  }
</style>
