<template>
  <Confirm
    :show.sync="paying"
    yes-btn="去支付"
    no-btn="去取消"
    @yes="yes"
    @no="no"
  >
    您有未支付的订单，您可以选择去支付或重新报名
  </Confirm>
</template>
<script>
import api from '@/api'
export default {
  name: 'CheckOrderState',
  props: {
    openId: {
      type: String,
      required: true,
      default: ''
    },
    eduTypeCode: {
      type: [ Number, String ],
      required: true,
      default: ''
    },
  },
  data: () => ({
    paying: false, // 是否存在正在支付的订单，true存在，false不存在
    yesLoading: false,
    noLoading: false,
  }),
  created() {
    this.checkOrderState()
  },
  methods: {
    checkOrderState () {
      const openId = this.openId
      const eduTypeCode = this.eduTypeCode
      const params = { params: { openId, eduTypeCode } }
      this.$http.get(api.pay.listPayingOrder, params)
      .then (response => {
        const list = response.data
        this.paying = list.length > 0
      })
    },
    yes () {
      this.$router.push({ path: '/Study' })
    },
    no () {
      this.$router.push({ path: '/Study' })
    },
  },
}
</script>
