<template>
  <v-bottom-navigation
    app
    grow
    :value="value"
    color="primary"
    class="d-flex align-center"
    @change="change"
  >
    <v-btn
      color="white"
      fab
      @click="to('/', queryStr)"
    >
      <span>首页</span>
      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn
      color="white"
      fab
      @click="to('/Study')"
    >
      <span>学习</span>
      <v-icon>mdi-book-open-variant</v-icon>
    </v-btn>

    <v-btn
      color="white"
      fab
      @click="to('/My')"
    >
      <span>我的</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getQueryStr } from '@/utils/storageUtils'
export default {
  name: 'BottomNav',
  data: () => ({
    queryStr: {},
  }),
  computed: {
    ...mapGetters([ 'GET_INDEX' ]),
    value: {
      get () {
        return this.GET_INDEX
      },
    }
  },
  created() {
    this.queryStr = getQueryStr() || {}
  },
  methods: {
    ...mapMutations([
      'SET_INDEX',
    ]),
    change (index) {
      this.SET_INDEX(index)
    },
    to (path, query) {
      this.$router.push({ path, query })
    }
  }
};
</script>
