<template>
  <v-app>
    <v-main>
      <v-overlay
        class="align-start justify-end"
      >
        <div
          class="d-flex flex-column align-end"
        >
          <v-img
            src="../../assets/righttop.png"
            contain
            width="100"
            class="mt-6 mr-6"
          />
          <v-list
            class="mx-12 mt-2"
            style="background: none!important;"
          >
            <v-list-item>
              <div
                class="rounded-circle red pa-3 py-1 d-inline-block mr-2" 
              >
                1
              </div>
              点击右上角
              <v-chip
                color="white"
                text-color="white"
                outlined
                label
                small
                class="mx-2"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-chip>
              按钮
            </v-list-item>
            <v-list-item>
              <div
                class="rounded-circle red pa-3 py-1 d-inline-block mr-2" 
              >
                2
              </div>
              选择
              <v-chip
                color="white"
                text-color="white"
                outlined
                label
                small
                class="mx-2"
              >
                在浏览器中打开
              </v-chip>
            </v-list-item>
          </v-list>
        </div>
      </v-overlay>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'DownFile',
  data() {
    return {
      fileName: undefined,
      url: undefined,
    }
  },
  created () {
    const { url, fileName } = this.$route.query
    this.url = url
    this.fileName = fileName
    this.download()
  },
  methods: {
    download () {
      const is_weixin = this.is_weixin()
      if (!is_weixin) {
        const a = document.createElement("a")
        a.setAttribute("href", this.url)
        a.setAttribute("download", this.fileName)
        a.setAttribute("target", "_blank")
        a.setAttribute("style", "display:blok");
        const clickEvent = document.createEvent("MouseEvents");
        clickEvent.initEvent("click", true, true);
        a.dispatchEvent(clickEvent);
      }
    },
  },
}
</script>
